import { DocsLightTheme } from "@gadgetinc/widgets/src/DocsTheme";
import { MDXProvider } from "@mdx-js/react";
import { BaseProvider } from "baseui";
import { ToasterContainer as BaseToasterContainer, PLACEMENT } from "baseui/toast";
import type { LayoutProps } from "fastify-renderer/client/react";
import { useNavigationDetails } from "fastify-renderer/client/react";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { getAppUrqlClient } from "state-trees/src/urql";
import { Client } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { Provider as UrqlProvider } from "urql";
import DocsDevFavicon from "web/public/assets/docs/dev/favicon-32.png";
import DocsProdFavicon from "web/public/assets/docs/prod/favicon-32.png";
import { LoadHubSpotChat } from "web/src/components/auth/LoadHubSpotChat";
import { CenteredSpin } from "web/src/components/chrome/CenteredSpin";
import { PageLoadingIndicator } from "web/src/components/chrome/PageLoadingIndicator";
import { usePageTracking } from "web/src/lib/eventTracker";
import { useEmitReactReady } from "web/src/lib/useEmitReactReady";
import { CatchAllErrorBoundary } from "./chrome/CatchAllErrorBoundary";
import { useDocsLocation } from "./chrome/nav/useDocsLocation";
import { MarkdownComponents } from "./markdown-components";

import "minireset.css";
import { DevelopmentEnvironmentSlug, EnvironmentType } from "state-trees/src/Environment";
import { FullMetaBlob } from "./chrome/FullMetaBlob";
import "./chrome/global.css";

const ToasterContainer = (props: { children: React.ReactNode }) => {
  if (import.meta.env.SSR) {
    return <>{props.children}</>;
  } else {
    return <BaseToasterContainer placement={PLACEMENT.bottomRight}>{props.children}</BaseToasterContainer>;
  }
};

const getHydrations = () => document.getElementsByClassName("_styletron_hydrate_") as HTMLCollectionOf<HTMLStyleElement>;

export const styletronEngine = import.meta.env.SSR
  ? ({} as any)
  : new Client({
      hydrate: getHydrations(),
      prefix: "g-",
    });

const urlBase = (path: string) => path.split("#")[0];

const RootContainer = (props: LayoutProps) => {
  const [path] = useDocsLocation();

  // we don't want to show the on-page progress indicator when navigating to a spot on the same page, so we check to see if the next place we're going has a different path
  const [isNavigating, navigationDestination] = useNavigationDetails();
  const isOffpageNavigating = isNavigating && urlBase(navigationDestination) != urlBase(path);

  const currentApp = props.bootProps.currentApp as FullMetaBlob | undefined;

  const urql = getAppUrqlClient(currentApp?.environment?.slug ?? DevelopmentEnvironmentSlug);
  const isProduction = currentApp?.environment?.type == EnvironmentType.Production;

  useEmitReactReady();
  usePageTracking(() => {
    const { id: applicationId, environmentID: environmentId } = props.bootProps.currentApp || {};

    return {
      applicationId: String(applicationId),
      environmentId: String(environmentId),
    };
  });

  const app = (
    <BaseProvider
      theme={DocsLightTheme}
      overrides={{ AppContainer: { style: { minHeight: "100vh", display: "flex", flexDirection: "column" } } }}
      zIndex={2}
    >
      <ToasterContainer>
        <UrqlProvider value={urql}>
          <MDXProvider components={MarkdownComponents}>
            <Helmet>
              <link rel="icon" href={isProduction ? DocsProdFavicon : DocsDevFavicon} />
              {path.startsWith("/api") && <meta name="robots" content="noindex" />}
            </Helmet>
            {isOffpageNavigating && <PageLoadingIndicator />}
            <CatchAllErrorBoundary>
              <Suspense fallback={<CenteredSpin />}>{props.children}</Suspense>
              <LoadHubSpotChat path="/api/identify-support-conversation" />
            </CatchAllErrorBoundary>
          </MDXProvider>
        </UrqlProvider>
      </ToasterContainer>
    </BaseProvider>
  );

  if (import.meta.env.SSR) {
    return app;
  } else {
    return <StyletronProvider value={styletronEngine}>{app}</StyletronProvider>;
  }
};

export default RootContainer;
